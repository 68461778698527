// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

@import './styles/color-palete.scss';
@import './styles/fonts.scss';
@import '@angular/material/theming';

$catTownPunks-primary: mat.define-palette($md-accentpalette, 500);
$catTownPunks-accent: mat.define-palette($md-orangepalette, 500);
$catTownPunks-warn: mat.define-palette(mat.$red-palette);

$catTownPunks-theme: mat.define-light-theme((
  color: (
    primary: $catTownPunks-primary,
    accent: $catTownPunks-accent,
    warn: $catTownPunks-warn,
  )
));
@include mat.all-component-themes($catTownPunks-theme);


$primary: map-get($catTownPunks-theme, primary);
$accent: map-get($catTownPunks-theme, accent);


/* You can add global styles to this file, and also import other style files */
@import url(./styles/layout.scss);

$primary-color: #e3324b;
$secondary-color: #F9A81B;
$font-color: #B5B5B3;
$font-color2: #808080;

body {
  font-family: Poppins;
  font-weight: normal;
  color: $font-color2;
  letter-spacing: normal;
  margin: 0;
  background-image: url('./assets/background.jpg');
}


.spooky {
  font-family: 'Creepster', cursive !important;
}


h1 {
  font-family: Termina;
  font-weight: bold;
  font-size: 80px;
  color: $primary-color;
  line-height: 86px;
  letter-spacing: normal;
  margin-top: 10rem;
}

h2 {
  font-family: Termina-Bold;
  font-size: 3.3em;
  color: #e3324b;
  line-height: 1.5em;
  letter-spacing: normal
}

p, td {
  font-family: Poppins;
  font-weight: normal;
  font-size: 24px;
  color: $font-color2;
  line-height: 35px;
  letter-spacing: normal;
}

b, th {
  font-family: Poppins;
  font-weight: bold;
}

li {
  font-family: Poppins;
  color: $font-color2;
  font-weight: normal;
  font-size: 24px;
}

.btn-primary {
  height: 71px;
  font-family: Poppins;
  font-weight: bold;
  font-size: 24px;
  color: white;
  line-height: 35px;
  letter-spacing: normal;
  border-radius: 36px !important;
}

.cat-button {
  margin-top: 20px;
  font-family: Poppins-Bold;
  font-size: 20px;
  min-width: 200px;
  height: 50px;
  border-radius: 2em !important;
}

.word-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}


@media screen and (max-width: 600px) {
  h1 {
    font-family: Termina;
    font-weight: bold;
    font-size: 45px;
    color: $primary-color;
    line-height: 56px;
    letter-spacing: normal;
  }
  h2 {
    font-size: 35px;
    line-height: 40px;
    text-align: center;
  }
  p {
    font-size: 20px;
  }
}

a {
  text-decoration: none;
  color: #e3324b;
  font-weight: bold;
}


/* MODAL */
.modal-button {
  margin-right: 20px;
  font-family: Poppins-SemiBold;
  font-size: 20px;
  min-width: 150px;
  height: 50px;
  border-radius: 2em;
}

.modal-title {
  font-family: Poppins-Regular;
  color: $primary-color;
  line-height: 1em;
  font-size: 2em;
}

.modal-icon {
  transform: scale(1.5);
}

.modal-text {
  font-family: Poppins-Regular;
  font-size: 1.4em;
  color: $font-color2;
  line-height: 1.2em;
  margin: 5px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .modal-title {
    font-size: 1.3rem;
  }
  .modal-button {
    font-size: 16px;
  }

}

td {
  margin: 6px;
}

th {
  text-align: left;
}

table {
  width: 100%;
}


.roadmap-page {
  ul {
    list-style-image: url('assets/check_false.png');
  }
  ul li {

    line-height: 100px;
  }
  ul li span {
    position: relative; top:-19px;
  }
  .checked {
    list-style-image: url('assets/check.png');
  }
}

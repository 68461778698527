@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"), url(../assets/fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"), url(../assets/fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"), url(../assets/fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Termina-Regular";
  src: local("Termina-Regular"), url(../assets/fonts/Termina/Termina-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Termina-Bold";
  src: local("Termina-bold"), url(../assets/fonts/Termina/Termina-Bold.ttf) format("truetype");
}



/////////////////// NEW FONTS ////////////////
@font-face {
  font-family: "Termina";
  src: url(../assets/fonts/Termina/Termina-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src:url(../assets/fonts/Poppins/Poppins-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src:url(../assets/fonts/Poppins/Poppins-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src:url(../assets/fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src:url(../assets/fonts/Poppins/Poppins-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}
